import styles from "./Card.module.css";

export interface CardProps {
    title: string;
    subtitle?: string;
    flavorText?: string;
    description: string;
    image?: string; //Square logos need a 200px pad on all sides
    image_alt?: string;
}

function Card(props: CardProps) {
    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <h2>{props.title}</h2>
                {props.subtitle && <h4>{props.subtitle}</h4>}
                {props.flavorText && <h4><span className={styles.flavor}>{props.flavorText}</span></h4>}
                <p>{props.description}</p>
            </div>
            {props.image && <div className={styles.image}>
                 <img src={"/img/" + props.image} alt={props.image_alt} />
            </div>}
        </div>
    );
}

export default Card;