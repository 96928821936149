import React from 'react';
import './App.module.css';
import Home from "./Home";
import Experience from "./Experience";
import TopButton from "./TopButton";
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.app}>
        <Home />
        <Experience />
        <TopButton />
    </div>
  );
}

export default App;
