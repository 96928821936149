import {useEffect, useState} from "react";
import styles from "./TopButton.module.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function TopButton() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 400) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <div className={styles.container}>
            <button onClick={scrollToTop} className={[
                styles.scroll_button,
                isVisible ? styles.visible : styles.invisible,
            ].join(" ")}>
                <span><KeyboardArrowUpIcon fontSize={"large"} /></span>
            </button>
        </div>
    )
}

export default TopButton;