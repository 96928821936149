import React, {useEffect, useState} from "react";
import styles from "./Experience.module.css";
import data from "./dat/experience.json";
import Card from "./Card";
import Divider from '@mui/material/Divider';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface WorkExperience {
    title: string;
    company: string;
    date: string;
    description: string;
    image_name: string;
}

interface Education {
    school: string;
    degree: string;
    date: string;
    description: string;
    image_name: string;
}

interface Project {
    title: string;
    client: string;
    description: string;
}

interface Skills {
    title: string;
    items: string[];
}

interface Data {
    work: WorkExperience[];
    education: Education[];
    projects: Project[];
    skills: Skills[];
}

function ScrollButton({up, to} : {up: boolean, to: string}) {
    function scrollTo() {
        const element = document.getElementById(to);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    }

    return (
        <button onClick={scrollTo} className={styles.scroll_button}>
            <span>
                {up ? <KeyboardArrowUpIcon fontSize={"medium"}/> : <KeyboardArrowDownIcon fontSize={"medium"}/>}
            </span>
        </button>
    )
}

function Experience() {
    const [mobile, setMobile] = useState(window.matchMedia('(min-width: 767px)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        const handler = (e: any) => setMobile(e.matches);
        mediaQuery.addEventListener("change", handler);
        setMobile(mediaQuery.matches);

        return () => {
            mediaQuery.removeEventListener("change", handler);
        };
    }, []);

    const experience: Data = data as unknown as Data;

    return (
        <div id={"experience"} className={styles.container}>
            <div className={styles.section} id={"work_experience"}>
                <h1>Work Experience</h1>
                {mobile && <ScrollButton up={false} to={"education"}/>}
            </div>
            {experience.work.map((exp, index) => {
                return (
                    <React.Fragment key={index}>
                        <Card
                            title={exp.title}
                            subtitle={exp.company}
                            flavorText={exp.date}
                            description={exp.description}
                            image={exp.image_name}
                            image_alt={exp.company + " logo"}
                        />
                        {index !== experience.work.length - 1 && <Divider
                            style={{width: "85%", background: "#adadad", marginBottom: "5px"}}
                            color={""}
                        />}
                    </React.Fragment>
                )
            })}
            <div className={styles.section} id={"education"}>
                {mobile && <ScrollButton up={true} to={"work_experience"}/>}
                <h1>Education</h1>
                {mobile && <ScrollButton up={false} to={"projects"}/>}
            </div>
            {experience.education.map((edu, index) => {
                return (
                    <React.Fragment key={index}>
                        <Card
                            title={edu.school}
                            subtitle={edu.degree}
                            flavorText={edu.date}
                            description={edu.description}
                            image={edu.image_name}
                            image_alt={edu.school + " logo"}
                        />
                        {index !== experience.education.length - 1 && <Divider
                            style={{width: "85%", background: "#adadad", marginBottom: "5px"}}
                            color={""}
                        />}
                    </React.Fragment>
                )
            })}
            <div className={styles.section} id={"projects"}>
                {mobile && <ScrollButton up={true} to={"education"}/>}
                <h1>Personal Projects</h1>
                {mobile && <ScrollButton up={false} to={"skills"}/>}
            </div>
            {experience.projects.map((proj, index) => {
                return (
                    <React.Fragment key={index}>
                        <Card
                            title={proj.title}
                            subtitle={proj.client}
                            description={proj.description}
                        />
                        {index !== experience.projects.length - 1 && <Divider
                            style={{width: "85%", background: "#adadad", marginBottom: "5px"}}
                            color={""}
                        />}
                    </React.Fragment>
                )
            })}

            <div className={styles.section} style={{marginBottom: 0}} id={"skills"}>
                {mobile && <ScrollButton up={true} to={"projects"}/>}
                <h1>Skills</h1>
            </div>
            <div className={styles.skills}>
                {experience.skills.map((skill, index) => {
                    return (
                        <React.Fragment key={index}>
                            <h2>{skill.title}</h2>
                            <div className={styles.skills_entries}>
                                {skill.items.map((s, i) => {
                                    return <p key={i}>{s}</p>
                                })}
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

export default Experience;